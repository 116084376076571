<template>
  <v-container fluid>
    <v-toolbar dark color="light-green" height="64px">
      <v-icon @click="$router.go(-1)">fas fa-arrow-left</v-icon>
    <v-toolbar-title>{{ toolbarTitle }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <v-btn class="hidden-sm-and-down" flat :disabled="!breakdownValid || succeeded" :loading="false" @click="createBreakDown()">Breakdown</v-btn>
      <v-btn class="hidden-md-and-up" icon :disabled="!breakdownValid || succeeded" :loading="false" @click="buildupParts()">
        <v-icon>far fa-check-circle</v-icon>
      </v-btn>
    </v-toolbar-items>
    </v-toolbar>

    <v-progress-linear v-if="$apollo.loading" color="blue" indeterminate></v-progress-linear>
    <v-container v-else>
      <ag-grid-vue
        class="ag-theme-material ag-grid-traqsys"
        :gridOptions="gridOptions"
        :rowData="items"
        :frameworkComponents="frameworkComponents"
      />

    </v-container>
    <div class="will-status-controls">
      <v-btn color="info" @click="dialog = true">Add Part</v-btn>
      <v-text-field
        v-model="totalValue"
        solo
        hide-details
      >
        <template #prepend>
          Total Value
        </template>
      </v-text-field>
      <v-text-field
        v-model="valueToReassign"
        readonly
        solo
        hide-details
        :rules="[ e => Number(e) === 0 || 'This must be zero' ]"
      >
        <template #prepend>
          Remaining Funds to Allocate
        </template>
      </v-text-field>
    </div>

    <v-dialog
      width="unset"
      v-model="dialog"
      persistent
    >
      <v-card>
        <v-card-text>
          <add-part @updated="broAddPart = $event.value" />
        </v-card-text>
      <v-card-actions class="pt-4">
        <v-spacer></v-spacer>
        <v-btn color="error" @click="closeDialog()">Cancel</v-btn>
        <v-btn color="success" :disabled="!broAddPart.pn" @click="submitDialog()">Submit</v-btn>
      </v-card-actions>
      </v-card>
  </v-dialog>

      <v-card-actions style="background-color: #dfdfdf;">
        <v-container py-0 fluid grid-list-xl>
          <v-layout row nowrap justify-end align-center>
            <v-flex xs4 shrink>
              <printers :printer="printer" @updated="printer = $event.value"/>
            </v-flex>
            <v-flex shrink>
              <v-checkbox
                v-model="printTags"
                color="purple accent-4"
                label="Print Labels"
                />
            </v-flex>
            <t-alert v-if="type !== 'success'" class="mr-2" :message="message" :type="type"/>
              <v-spacer></v-spacer>
          </v-layout>
        </v-container>
      </v-card-actions>

  </v-container>
</template>
<script>

import addPart from '@/components/autocompletes/PartsAutofill'
// import veganGrid from './vegan-grid'
import tAlert from '@/components/notifications/tAlert'
import columns from '@/components/mixins/columns'
import { AgGridVue } from 'ag-grid-vue'
import { mapActions } from 'vuex'
import {
  CREATE_BREAK_DOWN,
  CREATE_BREAK_DOWN_ITEMS,
  GET_BREAK_DOWN,
  GET_BREAK_DOWN_ITEMS
  // UPDATE_BREAK_DOWN_STATUS,
} from '@/api/graphql/Constants/Disassembly'
import { CREATE_PURCHASED_ITEMS } from '@/api/graphql/Constants/Orders'
import { GET_COUNTRIES } from '@/api/graphql/Constants/Countries'
// import itemsautofill from '@/components/autocompletes/items'
import printers from '@/components/autocompletes/printers'

import countryOfOriginCellEditor from '@/components/renderers/countryOfOriginCellEditor'
import locationCellEditor from '@/components/renderers/locationCellEditor'
import partNumberCell from '@/components/renderers/partNumberCell'
import { matchKeyUpTo } from '@/api/helpers'

// import standard2 from '@/components/autocompletes/standard2'
// import tAlert from '@/components/notifications/tAlert'

export default {
  name: 'NewBreakDown',
  mixins: [columns],
  components: {
    // 'items-autofill': itemsautofill,
    // 'vegan-grid': veganGrid,
    'add-part': addPart,
    't-alert': tAlert,
    AgGridVue,
    // 'standard-autofill': standard2,
    // 'part-icon': partIcon
    'printers': printers
  },
  computed: {
    columnConfig () {
      return this.$store.getters['profile/tableConfig']('breakdown')
    },
    breakdownValid () {
      // not sure what to check for validity quite yet. So FALSE! lol
      return false
    },
    toolbarTitle () {
      if (this.breakdownComponent.ptid) {
        return `Breakdown System ${this.breakdownComponent.ptid}`
      } else {
        return 'Breakdown System'
      }
    },
    correctPrintSettings () {
      return (this.printer && this.printTags) || (!this.printer && !this.printTags)
    },
    valueToReassign () {
      const sum = this.items.reduce((accumulator, currentValue) => {
        return accumulator + (Number(currentValue.original_cost) || 0)
      }, 0)
      return (this.totalValue - sum).toFixed(2)
    },
    succeeded () {
      return this.type === 'success'
    }
  },
  watch: {
    items: function (value) {
    },
    dialog: function (value) {
      if (value) {
        this.getAddPartItemsList()
      }
    },
    columnConfig: function (value) {
      if (Object.prototype.toString.call(value) === '[object Array]' && this.columnsNotSet) {
        this.columnApi.setColumnState(value)
      }
    },
    partID: function (value) {
      if (value) {
        // const bomObject = this.addPartsList.filter(b => b.id === value)[0]
        // this.selectBOM(bomObject)
      }
    }
  },
  data () {
    return {
      broAddPart: {},
      successMessage: '',
      partID: 0,
      totalValue: 0,
      breakdownComponent: {},
      printLabels: false,
      parts: [],
      printer: 0,
      loadingParts: false,
      columns: [
        { headerName: 'Line', field: 'line_number', width: 100 },
        { headerName: 'Part Number', field: 'item.part.pn' },
        {
          headerName: 'Country of Origin',
          field: 'item.country_of_origin',
          editable: true,
          cellClass: 'editable',
          cellEditor: 'countryCellEditor',
          cellRenderer: (params) => {
            const name = params?.value?.name ?? ''
            const flag = params?.value?.flag ?? ''
            return `<span class="bold pr-2">${name}</span><span style="font-size: 30px">${flag}</spans>`
          },
          valueSetter: (params) => {
            params.data.item.country_of_origin = params.newValue
            return true
          }
        },
        {
          headerName: 'Location',
          field: 'item.location',
          cellClass: 'editable',
          editable: true,
          cellEditor: 'locationCellEditor',
          cellRenderer: (params) => {
            const value = params?.value?.name ?? ''
            return `<div class="mt-2 bold">${value}</div>`
          },
          valueSetter: (params) => {
            params.data.item.location = params.newValue
            return true
          }
        },
        { headerName: 'Description', field: 'item.part.description' },
        {
          headerName: 'Serial Number',
          field: 'item.serial_number',
          cellClass: 'editable',
          editable: true,
          cellRenderer: (params) => {
            return `<div class="mt-2 bold">${params?.value ?? ''}</div>`
          }
        },
        {
          headerName: 'Original Cost',
          field: 'original_cost',
          cellClass: 'editable',
          editable: true,
          cellRenderer: (params) => {
            return params.value || 0.00
          }
        }
      ],
      contextMenuItems: (params) => {
        const nodes = params.api.getSelectedNodes()

        const contextMenu = []

        const { locationObj, location } = this.checkForMultiLocationEdit(nodes)
        const { countryObj, country } = this.checkForMultiCountryEdit(nodes)
        const moreThanOneSelected = nodes.length > 1

        const locationDisabled = !moreThanOneSelected || !location
        const multiLocationEdit = {
          name: `Multi-Row Location Set: <b>${location || 'N/A'}</b>`,
          disabled: locationDisabled,
          shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">l</span>',
          action: () => {
            this.multiLocationEdit(nodes, locationObj)
          },
          tooltip: locationDisabled ? 'Select no-location rows and one row with a location' : ''
        }

        const countryDisabled = !moreThanOneSelected || !country
        const multiCountryEdit = {
          name: `Multi-Row Country Set: <b>${country || 'N/A'}</b>`,
          disabled: countryDisabled,
          shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">c</span>',
          action: () => {
            this.multiCountryEdit(nodes, countryObj)
          }
        }

        contextMenu.push(multiLocationEdit, multiCountryEdit)

        return contextMenu
      },
      columnConfigKey: '',

      printTags: false,
      message: '',
      dialog: false,
      type: 'info',
      saving: false,
      fetched: false,
      loading: true,
      items: [],
      countries: [],
      columnsNotSet: false,

      /* Grid Variables */
      frameworkComponents: null,
      // grid setup
      gridOptions: null,
      // end gridOptions
      editableNodes: [],
      gridApi: null,
      columnApi: null
    }
  },
  apollo: {
    breakdownComponent: {
      query: GET_BREAK_DOWN,
      variables () {
        return {
          id: this.$route.params.id
        }
      },
      update (data) {
        const out = {}
        if (data.breakdown) {
          out.data = data.breakdown
          out.ptid = `${data?.breakdown?.system?.['purchases_items_details']?.transaction?.id}-${data.breakdown?.system?.['purchases_items_details']?.['line_number']}`
        }
        return out
      },
      fetchPolicy: 'no-cache'
    },
    countries: {
      query: GET_COUNTRIES
    },
    items: {
      query: GET_BREAK_DOWN_ITEMS,
      variables () {
        return {
          id: this.$route.params.id
        }
      },
      update (data) {
        this.totalValue = 0
        this.fetched = true
        return data.breakDownItems.map(i => {
          this.totalValue += Number(i.item.purchases_items_details.original_cost || 0)
          return i
        })
      },
      fetchPolicy: 'no-cache',
      watchLoading (isLoading) {
        this.superloading = isLoading
      }
    }
  },
  methods: {
    closeDialog () {
      this.dialog = false
      this.broAddPart = {}
    },
    submitDialog () {
      this.createPurchasedItem(this.broAddPart)
      this.closeDialog()
    },
    ...mapActions('notifications', {
      createSnackbar: 'createSnackbar'
    }),
    selectAddParts () {
    },
    addPartsToBreakDown () {
    },
    createBreakDown () {
      this.building = true
      this.$apollo.mutate({
        mutation: CREATE_BREAK_DOWN,
        variables: {
          id: this.$route.params.id
        }
      }).then(async ({ data: { breakDown } }) => {
        const breakDownID = breakDown.id
        const promises = []
        const errorPtIDs = []
        for (const item of this.inventoryItemsToBuildUp) {
          const promise = this.$apollo.mutate({
            mutation: CREATE_BREAK_DOWN_ITEMS,
            variables: { id: breakDownID, item_id: item.id }
          }).catch(() => {
            errorPtIDs.push(item.ptID)
            return false
          })
          promises.push(promise)
        }

        Promise.all(promises).then(() => {
          if (errorPtIDs.length > 0) {
            let message = ''
            for (const ptID of errorPtIDs) {
              message += `<span class="emphasis">${ptID}</span>`
            }
            this.incorrectPartMessage = 'The break down up was successfully created with the following errors:'
            this.notFoundMessage = `These items could not be added: ${message}.`
            this.building = false
          } else {
            this.successMessage = 'Successfully created the break down up!'
            this.building = false
          }
        })
      })
    },

    multiLocationEdit (nodes, locationObj) {
      for (const node of nodes) {
        node.data.item.location = locationObj
      }
      this.gridApi.redrawRows()
    },

    multiCountryEdit (nodes, countryObj) {
      for (const node of nodes) {
        node.data.item.country_of_origin = countryObj
      }
      this.gridApi.redrawRows()
    },

    checkForMultiCountryEdit (nodes) {
      let countryObj = {}
      let country = ''
      let canSetMultipleCountries = nodes.length > 1
      if (canSetMultipleCountries) {
        for (const node of nodes) {
          if (!country) {
            const name = node.data?.item?.['country_of_origin']?.name ?? false
            if (name) {
              country = name
              countryObj = node.data.item.country_of_origin
            }
          } else {
            if ((node.data?.item?.['country_of_origin']?.name ?? false) && node.data?.item?.['country_of_origin']?.name !== country) {
              canSetMultipleCountries = false
              break
            }
          }
        }
      }
      return { countryObj: countryObj, country: country }
    },

    createPurchasedItem (part) {
      this.$apollo.mutate({
        mutation: CREATE_PURCHASED_ITEMS,
        variables: { items: [part] }
      }).then(async (data) => {
      })

    //   this.$apollo.mutate({
    //     mutation: CREATE_BREAK_DOWN_ITEMS,
    //     variables: { id: id, item_id: item }
    //   }).then(async ({ data: { stuff } }) => {
    //   })
    },

    checkForMultiLocationEdit (nodes) {
      let locationObj = {}
      let location = ''
      let canSetMultipleLocations = nodes.length > 1
      if (canSetMultipleLocations) {
        for (const node of nodes) {
          if (!location) {
            if (node.data?.item?.location?.name ?? false) {
              location = node.data.item.location.name
              locationObj = node.data.item.location
            }
          } else {
            if ((node.data?.item?.location?.name ?? false) && node.data?.item?.location?.name !== location) {
              canSetMultipleLocations = false
              break
            }
          }
        }
      }
      return { locationObj: locationObj, location: location }
    },
    keyUpChecker (event) {
      const nodes = this.gridApi.getSelectedNodes()
      if (['INPUT', 'TEXTAREA'].indexOf(document.activeElement.nodeName) === -1) {
        if (!event.altKey) {
          if (matchKeyUpTo(event, 'l')) {
            const { locationObj, location } = this.checkForMultiLocationEdit(nodes)
            location && this.multiLocationEdit(nodes, locationObj)
          } else if (matchKeyUpTo(event, 'c')) {
            const { countryObj, country } = this.checkForMultiCountryEdit(nodes)
            country && this.multiCountryEdit(nodes, countryObj)
          }
          /* Reverse : with altKey */
        } else {
          if (matchKeyUpTo(event, 'l')) {
            for (const node of nodes) {
              node.setDataValue('item.location', null)
            }
          } else if (matchKeyUpTo(event, 'c')) {
            for (const node of nodes) {
              node.setDataValue('item.country_of_origin', null)
            }
          }
        }
      }
    }
  },
  beforeMount () {
    //
    // Lets generate some random crap
    // let sup = Array.from({ length: 20 }, (item, index) => {
    //   return Math.floor(Math.random() * 1000)
    // })
    // sup.forEach((item, index) => {
    //   this.$apollo.mutate({
    //     mutation: CREATE_BREAK_DOWN_ITEMS,
    //     variables: { id: id, item_id: item }
    //   }).then(async ({ data: { stuff } }) => {
    //   })
    // })
    this.gridOptions = {
      allowDragFromColumnsToolPanel: true,
      animateRows: true,
      // cacheBlockSize: 50,
      // cacheOverflowSize: 30,
      // maxConcurrentDatasourceRequests: 2,
      columnDefs: this.columnize(this.columns),
      defaultColDef: {
        cellClass: (params) => {
          let search
          if (params.data === undefined) {
            search = params.node.id
          } else {
            search = params.data[Object.keys(params.data)[0]] + ' ' + params.colDef.field
          }
          if (this.editableNodes.indexOf(search) > -1) {
            return 'editable pt-1'
          }
          return 'pt-1'
        },
        editable: (params) => {
          const search = params.data[Object.keys(params.data)[0]] + ' ' + params.colDef.field
          if (this.editableNodes.indexOf((search)) > -1) {
            return 'editable'
          }
        },
        enablePivot: false,
        enableRowGroup: false,
        enableValue: false,
        menuTabs: ['generalMenuTab'],
        resizable: true,
        sortable: true
      },
      editType: 'singleCell',
      enterMovesDownAfterEdit: true,
      getContextMenuItems: this.contextMenuItems,
      getRowNodeId: (data) => {
        return data.id
      },
      headerHeight: 50,
      onDragStopped: (params) => {
        const columnState = params.columnApi.getColumnState()
        this.$store.dispatch('profile/updateTableConfig', { table: 'breakdown', config: columnState })
      },
      onCellValueChanged: (params) => {
      },
      onColumnResized: (params) => {
        if (params.finished) {
          const columnState = params.columnApi.getColumnState()
          this.$store.dispatch('profile/updateTableConfig', { table: 'breakdown', config: columnState })
        }
      },
      onGridReady: (params) => {
        this.gridApi = params.api
        this.columnApi = params.columnApi
        this.$emit('ready', params)
        if (this.columnConfig) {
          params.columnApi.setColumnState(this.columnConfig)
        } else {
          this.columnsNotSet = true
        }
      },
      rowDeselection: true,
      rowHeight: 40,
      rowModelType: 'clientSide',
      rowSelection: 'multiple',
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true
            }
          }
        ]
      },
      singleClickEdit: true,
      sortingOrder: ['asc', 'desc'],
      stopEditingWhenGridLosesFocus: true,
      suppressMultiRangeSelection: true,
      suppressPropertyNamesCheck: true
    }

    this.frameworkComponents = {
      partNumberCell: partNumberCell,
      locationCellEditor: locationCellEditor,
      countryCellEditor: countryOfOriginCellEditor
    }
  },
  mounted () {
    window.addEventListener('keyup', this.keyUpChecker)
  },
  destroyed () {
    window.removeEventListener('keyup', this.keyUpChecker)
  },
  beforeDestroy () {
  }
}

</script>

<style scoped>

.will-grid {
  display: grid;
  align-content: stretch;
  grid-template-columns: 1fr;
  grid-template-rows: 64px 1fr auto 84px;
}

.will-status-controls {
  padding: 8px;
  display: grid;
  align-content: center;
  justify-content: center;
  grid-gap: 20px;
  grid-template-rows: 1fr;
  grid-template-columns: minmax(24px, 100px) minmax(100px, 300px) minmax(100px, 400px);
}

.value-section {
  padding: 8px;
}

</style>
