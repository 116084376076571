<template>
  <v-autocomplete
    v-model="country"
    :loading="loading"
    :items="countries"
    :filter="filter"
    return-object
    item-text="name"
    ref="countryEdit"
    @change="onClick"
  >
    <template #item="{ item }">
      <v-list-tile-title>
        <v-layout justify-space-between>
          <v-flex>{{ item.name }}</v-flex>
          <v-flex class="text-xs-right" style="font-size: 22px;">{{ item.flag }}</v-flex>
        </v-layout>
      </v-list-tile-title>
    </template>
  </v-autocomplete>
</template>

<script>
import Vue from 'vue'
import { GET_COUNTRIES } from '@/api/graphql/Constants/Countries'
export default Vue.extend({
  name: 'countryOfOriginCellEditor',
  computed: {
    country: {
      get () {
        return this.$_.get(this.params.data, 'item.country_of_origin', { code: '', flag: '', name: '' })
      },
      set (value) {
        this.params.node.setDataValue('countryOfOrigin', value)
      }
    }
  },
  data () {
    return {
      countries: [],
      loading: false
    }
  },
  apollo: {
    countries: {
      query: GET_COUNTRIES,
      watchLoading (isLoading) {
        this.loading = isLoading
      },
      fetchPolicy: 'cache-only'
    }
  },
  methods: {
    getValue () {
      return this.country
    },

    refresh () {
      return true
    },

    onClick (country) {
      this.country = country
      this.params.api.stopEditing()
    },

    filter (item, search) {
      const name = item.name.toLowerCase()
      const code = item.code.toLowerCase()
      const lowerCaseSearch = search.toLowerCase()

      return name.indexOf(lowerCaseSearch) > -1 || code.indexOf(lowerCaseSearch) > -1
    }
  },
  mounted () {
    Vue.nextTick(() => {
      this.$refs.countryEdit.focus()
      this.$refs.countryEdit.isMenuActive = true
    })
  }
})
</script>
