<template>
  <v-autocomplete
    v-model="location"
    :loading="loading"
    :items="locations"
    item-text="name"
    return-object
    ref="locationEdit"
    @change="onClick"
  >
    <template #item="{ item }">
      <v-list-tile-title>
        {{ item.name }}
      </v-list-tile-title>
    </template>
  </v-autocomplete>
</template>

<script>
import Vue from 'vue'
import { GET_LOCATIONS } from '@/api/graphql/Constants/Locations'
export default Vue.extend({
  name: 'locationCellEditor',
  computed: {
    location: {
      get () {
        return this.$_.get(this.params.data, 'item.location', { id: 0, name: '' })
      },
      set (value) {
        this.params.node.setDataValue('location', value)
      }
    }
  },
  data () {
    return {
      locations: [],
      loading: false
    }
  },
  apollo: {
    locations: {
      query: GET_LOCATIONS,
      watchLoading (isLoading) {
        this.loading = isLoading
      }
    }
  },
  methods: {
    getValue () {
      return this.location
    },

    refresh () {
      return true
      // this.$apollo.queries.locations.refetch()
    },

    onClick (location) {
      this.location = location
      this.params.api.stopEditing()
    }
  },
  mounted () {
    Vue.nextTick(() => {
      this.$refs.locationEdit.focus()
      this.$refs.locationEdit.isMenuActive = true
    })
  }
})
</script>
